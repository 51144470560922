import { Link } from 'gatsby';
import { Heading, Badge } from 'theme-ui';
import { categoryBadge } from '@/styles/mdxStyles';
import * as React from 'react';
export default {
  Link,
  Heading,
  Badge,
  categoryBadge,
  React
};